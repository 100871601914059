const store = {
    state: {
        _loading: false,
        _count: 0
    },
    getters: {
        loading: state => {
            return state._loading;
        },
    },
    mutations: {
        loading_start(state) {
            state._count++
            state._loading = true
        },
        loading_end(state) {
            if (state._count > 0) state._count--
            if (state._count == 0)
                state._loading = false
        }
    },
}

export default store
