const store = {
    state: {
        opened: false
    },
    getters: {
        WIDTH: state => {
            return state.opened ? 290 : 110;
        },
    },
    mutations: {
        opened(state, active) {
            state.opened = active
        }
    },
}

export default store
